import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  public constructor(private translateService: TranslateService) {}

  public async init(): Promise<void> {
    this.translateService.addLangs(["de", "en"]);
    const browserLanguage = this.getFirstBrowserLanguage();
    this.translateService.setDefaultLang(browserLanguage);
    this.translateService.use(browserLanguage);
  }

  public getFirstBrowserLanguage() {
    const lang = this.translateService.getBrowserLang();
    return lang === "de" ? "de" : "en";
  }
}
